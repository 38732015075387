@import "nprogress/nprogress";

#nprogress .bar {
  background: #0d1418;
}

/* Fancy blur effect */
#nprogress .peg {
  box-shadow: 0 0 10px #0d1418, 0 0 5px #0d1418;
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: none;
}

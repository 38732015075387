@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .smooth-scroll {
    scroll-behavior: smooth;
  }

  .text-size-adjust {
    -webkit-text-size-adjust: 100%;
  }
  .optimize-legibility {
    text-rendering: optimizeLegibility;
  }
  .text-shadow-xs {
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  }
  .no-ligatures {
    font-variant-ligatures: none;
  }

  .h-screen-minus-header {
    height: calc(100vh - theme("spacing.44"));
  }

  .indent-sm {
    text-indent: 36px;
  }

  .indent-md {
    text-indent: 36px;
  }

  .indent-lg {
    text-indent: 46px;
  }

  .indent-xl {
    text-indent: 64px;
  }

  .indent {
    text-indent: theme("width.12");
  }

  .hover-underline,
  .group-hover-underline {
    @apply no-underline;
  }

  .hover-underline::before,
  .group-hover-underline::before {
    @apply bg-current;
    @apply absolute -bottom-1 left-0;
    @apply h-[2px] w-full;
    @apply content-[""];
    @apply block;
    @apply origin-left scale-0 transform;
    @apply transition-transform;
  }

  .hover-underline::before {
    @apply hover:scale-100;
  }

  .group-hover-underline::before {
    @apply group-hover:scale-100;
  }

  .text-gradient {
    @apply bg-gradient-to-br from-pink to-magenta;
    @apply bg-clip-text;
    @apply text-transparent;
    @apply decoration-clone;
  }

  .text-gradient-to-r {
    @apply bg-gradient-to-r from-pink to-magenta;
    @apply bg-clip-text;
    @apply text-transparent;
    @apply decoration-clone;
  }

  .markdown {
    em {
      @apply not-italic;
      @apply border-b-3;
      @apply border-pink;
    }
    strong {
      @apply text-gradient;
    }
    u {
      @apply border-b-3;
      @apply border-current no-underline decoration-0;
    }
  }

  .bold-children {
    strong {
      @apply font-bold;
    }
  }

  .link-children {
    a {
      @apply transition-opacity;
      @apply font-bold;
      &:hover {
        @apply opacity-60;
      }
    }
  }

  .gradient-columns {
    background: linear-gradient(
      90deg,
      rgba(theme("colors.white"), 0) 0%,
      rgba(theme("colors.white"), 0) 60%,
      rgba(theme("colors.navy"), 1) 60%,
      rgba(theme("colors.royal"), 1) 100%
    );
  }

  .child-video-object-cover {
    @apply relative;
    > video {
      @apply absolute inset-0;
      @apply object-cover object-center;
    }
  }

  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

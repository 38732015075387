@import "swiper/swiper-bundle.css";

/*
	Pagination
*/

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  background: theme("colors.navy");
  opacity: 0.1;
}

.swiper-pagination-bullet-active {
  opacity: 1;
}

.swiper-slide {
  height: auto;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.swiper-button-prev,
.swiper-button-next {
  @apply absolute top-1/2;
  @apply transition-opacity;
  @apply bg-pink hover:bg-pink/80;
  @apply h-12 w-12 rounded-full;
  @apply transition-colors;
  @apply shadow-md;
  @apply bg-no-repeat;
  @apply bg-center;
  @apply after:hidden;
  @apply z-40;
}

.swiper-button-next {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M9 18l6-6-6-6'/%3E%3C/svg%3E");

  @apply right-8;
  @apply active:translate-x-[1px];
}

.swiper-button-prev {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M15 18l-6-6 6-6'/%3E%3C/svg%3E");

  @apply left-8;
  @apply active:-translate-x-[1px];
}

.swiper-button-disabled {
  @apply opacity-50;
  @apply pointer-events-none;
}
